<template>
    <DxToolbar style="height: 56px" class="toolbar">
        <DxItem location="before" locate-in-menu="never">
            <template #default>
                <div class="row align-center">
                    <DxButton
                        type="normal"
                        styling-mode="text"
                        icon="mdi mdi-chevron-left"
                        class="mr-2"
                        @click="$router.push('/')"
                    />
                    <routerVue :breadcrumbsItems="breadcrumbsItems" />
                </div>
            </template>
        </DxItem>
    </DxToolbar>

    <div class="px-2">
        <div :class="rowTitle">Thông tin lệnh</div>
        <div :class="row" style="line-height: 1">
            <!-- <i class="mdi mdi-circle-small"></i> -->
            <div :class="textLeft">Mã lệnh</div>
            <div :class="textRight">
                {{ ChiTietLenh.maLenh }}
            </div>
        </div>
        <div :class="row" style="line-height: 1">
            <!-- <i class="mdi mdi-circle-small"></i> -->
            <div :class="textLeft">Trạng thái lệnh</div>
            <div :class="textRight" :style="`color:${ChiTietLenh.maMauTrangThai}`">
                {{ ChiTietLenh.trangThai }}
            </div>
        </div>
        <div :class="row" style="line-height: 1">
            <!-- <i class="mdi mdi-circle-small"></i> -->
            <div :class="textLeft">Người tạo lệnh</div>
            <div :class="textRight">
                {{ ChiTietLenh.nguoiTaoLenh }}
            </div>
        </div>
        <div :class="row" style="line-height: 1">
            <!-- <i class="mdi mdi-circle-small"></i> -->
            <div :class="textLeft">Thời gian tạo lệnh</div>
            <div :class="textRight">
                {{ $Helper.formatDateTime(ChiTietLenh.ngayTaoLenh) }}
            </div>
        </div>
        <div :class="row" style="line-height: 1">
            <!-- <i class="mdi mdi-circle-small"></i> -->
            <div :class="textLeft">Người cấp lệnh</div>
            <div :class="textRight">
                {{ ChiTietLenh.nguoiKyLenh }}
            </div>
        </div>
        <div :class="row" style="line-height: 1">
            <!-- <i class="mdi mdi-circle-small"></i> -->
            <div :class="textLeft">Thời gian cấp lệnh</div>
            <div :class="textRight">
                {{ $Helper.formatDateTime(ChiTietLenh.thoiGianKyLenh) }}
            </div>
        </div>

        <div :class="rowTitle">Thông tin chi tiết</div>
        <div :class="row" style="line-height: 1">
            <!-- <i class="mdi mdi-circle-small"></i> -->
            <div :class="textLeft">Giờ xuất bến</div>
            <div :class="textRight">
                {{ this.$Helper.Common.formatDateTime(ChiTietLenh.gioXuatBen) }}
            </div>
        </div>
        <div :class="row" style="line-height: 1">
            <!-- <i class="mdi mdi-circle-small"></i> -->
            <div :class="textLeft">Mã tuyến</div>
            <div :class="textRight">
                {{ ChiTietLenh.maTuyen }}
            </div>
        </div>
        <div :class="row" style="line-height: 1">
            <!-- <i class="mdi mdi-circle-small"></i> -->
            <div :class="textLeft">Biển kiểm soát</div>
            <div :class="textRight">
                {{ ChiTietLenh.bienKiemSoat }}
            </div>
        </div>
        <div :class="row" style="line-height: 1">
            <!-- <i class="mdi mdi-circle-small"></i> -->
            <div :class="textLeft">Lái xe</div>
            <div :class="textRight">
                {{ GetTextLaiXe }}
            </div>
        </div>

        <div :class="rowTitle">
            Thông tin giấy tờ xe
        </div>
        <div :class="row" style="line-height: 1">
            <!-- <i class="mdi mdi-circle-small"></i> -->
            <div :class="textLeft">Hạn phù hiệu</div>
            <div :class="textRight">
                {{ $Helper.formatDate(ChiTietLenh.hanPhuHieuTuyen) }}
            </div>
        </div>
        <div :class="row" style="line-height: 1">
            <!-- <i class="mdi mdi-circle-small"></i> -->
            <div :class="textLeft">Hạn đăng kiểm</div>
            <div :class="textRight">
                {{ $Helper.formatDate(ChiTietLenh.hanDangKiem) }}
            </div>
        </div>
        <div :class="row" style="line-height: 1">
            <!-- <i class="mdi mdi-circle-small"></i> -->
            <div :class="textLeft">Hạn bảo hiểm</div>
            <div :class="textRight">
                {{ $Helper.formatDate(ChiTietLenh.hanBaoHiem) }}
            </div>
        </div>

        <div :class="rowTitle">Thông tin DNVT</div>
        <div :class="row" style="line-height: 1">
            <!-- <i class="mdi mdi-circle-small"></i> -->
            <div :class="textLeft">Đơn vị</div>
            <div :class="textRight">
                {{ ChiTietLenh.tenDoanhNghiep }}
            </div>
        </div>

        <div :class="rowTitle">Thông tin thực tế</div>
        <div :class="row" style="line-height: 1">
            <!-- <i class="mdi mdi-circle-small"></i> -->
            <div :class="textLeft">Giờ xuất bến</div>
            <div :class="textRight">
                {{ $Helper.formatDateTime(ChiTietLenh.gioXuatBen) }}
            </div>
        </div>
        <div :class="row" style="line-height: 1">
            <!-- <i class="mdi mdi-circle-small"></i> -->
            <div :class="textLeft">Số khách xuất bến</div>
            <div :class="textRight">
                {{ ChiTietLenh.soKhachXuatBen }}
            </div>
        </div>
        <div :class="row" style="line-height: 1">
            <!-- <i class="mdi mdi-circle-small"></i> -->
            <div :class="textLeft">Giờ đến bến</div>
            <div :class="textRight">
                {{ $Helper.formatDateTime(ChiTietLenh.gioDenBen) }}
            </div>
        </div>
        <div :class="row" style="line-height: 1">
            <!-- <i class="mdi mdi-circle-small"></i> -->
            <div :class="textLeft">Số khách đến bến</div>
            <div :class="textRight">
                {{ ChiTietLenh.soKhachDenBen }}
            </div>
        </div>

        <div :class="rowTitle">Thông tin ký lệnh</div>
        <div :class="row" style="line-height: 1">
            <!-- <i class="mdi mdi-circle-small"></i> -->
            <div :class="textLeft">Loại lệnh</div>
            <div :class="textRight">
                {{ ChiTietLenh.hinhThucChay }}
            </div>
        </div>
        <div :class="row" style="line-height: 1">
            <!-- <i class="mdi mdi-circle-small"></i> -->
            <div :class="textLeft">Doanh nghiệp ký lệnh</div>
            <div
                :class="textRight"
                :style="`color:${
                    ChiTietLenh.thuTruongDonViDaKyLenh ? '#13B000' : '#D10909'
                }`"
            >
                {{ ChiTietLenh.thuTruongDonViDaKyLenh ? "Đã ký" : "Chưa ký" }}
            </div>
        </div>
        <div
            :class="row"
            style="line-height: 1"
            v-if="ChiTietLenh.thuTruongDonViDaKyLenh"
        >
            <!-- <i class="mdi mdi-circle-small"></i> -->
            <div :class="textLeft">Thời gian ký lệnh</div>
            <div :class="textRight">
                {{ this.$Helper.Common.formatDateTime(ChiTietLenh.thoiGianKyLenh) }}
            </div>
        </div>
        <div :class="row" style="line-height: 1">
            <!-- <i class="mdi mdi-circle-small"></i> -->
            <div :class="textLeft">Mã tuyến</div>
            <div :class="textRight">
                {{ ChiTietLenh.maTuyen }}
            </div>
        </div>
        <div :class="row" style="line-height: 1">
            <!-- <i class="mdi mdi-circle-small"></i> -->
            <div :class="textLeft">Tên bến đi</div>
            <div :class="textRight">
                {{ ChiTietLenh.benDi }}
            </div>
        </div>
        <div :class="row" style="line-height: 1">
            <!-- <i class="mdi mdi-circle-small"></i> -->
            <div :class="textLeft">Bến đi ký lệnh</div>
            <div
                :class="textRight"
                :style="`color:${ChiTietLenh.benDiDaKyLenh ? '#13B000' : '#D10909'}`"
            >
                {{ ChiTietLenh.benDiDaKyLenh ? "Đã ký" : "Chưa ký" }}
            </div>
        </div>
        <div :class="row" style="line-height: 1" v-if="ChiTietLenh.benDiDaKyLenh">
            <!-- <i class="mdi mdi-circle-small"></i> -->
            <div :class="textLeft">Thời gian bến đi ký</div>
            <div :class="textRight">
                {{ this.$Helper.Common.formatDateTime(ChiTietLenh.thoiGianBenDiKyLenh) }}
            </div>
        </div>

        <div :class="row" style="line-height: 1">
            <!-- <i class="mdi mdi-circle-small"></i> -->
            <div :class="textLeft">Tên bến đến</div>
            <div :class="textRight">
                {{ ChiTietLenh.benDen }}
            </div>
        </div>
        <div :class="row" style="line-height: 1">
            <!-- <i class="mdi mdi-circle-small"></i> -->
            <div :class="textLeft">Bến đến ký lệnh</div>
            <div
                :class="textRight"
                :style="`color:${ChiTietLenh.benDenDaKyLenh ? '#13B000' : '#D10909'}`"
            >
                {{ ChiTietLenh.benDenDaKyLenh ? "Đã ký" : "Chưa ký" }}
            </div>
        </div>
        <div :class="row" style="line-height: 1" v-if="ChiTietLenh.benDenDaKyLenh">
            <!-- <i class="mdi mdi-circle-small"></i> -->
            <div :class="textLeft">Thời gian bến đến ký</div>
            <div :class="textRight">
                {{ this.$Helper.Common.formatDateTime(ChiTietLenh.thoiGianBenDenKyLenh) }}
            </div>
        </div>

        <div :class="row" style="line-height: 1">
            <!-- <i class="mdi mdi-circle-small"></i> -->
            <div :class="textLeft">Truyền tải xe buýt</div>
            <div
                :class="textRight"
                :style="`color:${
                    ChiTietLenh.truyenTai_XeBuyt_ThanhCong == true
                        ? '#13B000'
                        : ChiTietLenh.truyenTai_XeBuyt_ThanhCong == null
                        ? '#FB8C00'
                        : '#D10909'
                }`"
            >
                {{
                    ChiTietLenh.truyenTai_XeBuyt_ThanhCong == null
                        ? "Chưa truyền tải"
                        : ChiTietLenh.truyenTai_XeBuyt_ThanhCong == true
                        ? "Truyền tải thành công"
                        : "Truyền tải gặp lỗi"
                }}
            </div>
        </div>
        <div
            :class="row"
            style="line-height: 1"
            v-if="ChiTietLenh.truyenTai_XeBuyt_ThanhCong"
        >
            <!-- <i class="mdi mdi-circle-small"></i> -->
            <div :class="textLeft">Thời gian truyền tải xe buýt</div>
            <div :class="textRight">
                {{
                    this.$Helper.Common.formatDateTime(
                        ChiTietLenh.thoiGianTruyenTaiXeBuyt
                    )
                }}
            </div>
        </div>

        <div :class="row" style="line-height: 1">
            <!-- <i class="mdi mdi-circle-small"></i> -->
            <div :class="textLeft">Truyền tải xuất bến</div>
            <div
                :class="textRight"
                :style="`color:${
                    ChiTietLenh.truyenTai_XuatBen_ThanhCong == true
                        ? '#13B000'
                        : ChiTietLenh.truyenTai_XuatBen_ThanhCong == null
                        ? '#FB8C00'
                        : '#D10909'
                }`"
            >
                {{
                    ChiTietLenh.truyenTai_XuatBen_ThanhCong == null
                        ? "Chưa truyền tải"
                        : ChiTietLenh.truyenTai_XuatBen_ThanhCong == true
                        ? "Truyền tải thành công"
                        : "Truyền tải gặp lỗi"
                }}
            </div>
        </div>
        <div
            :class="row"
            style="line-height: 1"
            v-if="ChiTietLenh.truyenTai_XuatBen_ThanhCong"
        >
            <!-- <i class="mdi mdi-circle-small"></i> -->
            <div :class="textLeft">Thời gian truyền tải xuất bến</div>
            <div :class="textRight">
                {{
                    this.$Helper.Common.formatDateTime(
                        ChiTietLenh.thoiGianTruyenTaiXuatBen
                    )
                }}
            </div>
        </div>

        <div :class="row" style="line-height: 1">
            <!-- <i class="mdi mdi-circle-small"></i> -->
            <div :class="textLeft">Truyền tải đến bến</div>
            <div
                :class="textRight"
                :style="`color:${
                    ChiTietLenh.truyenTai_DenBen_ThanhCong == true
                        ? '#13B000'
                        : ChiTietLenh.truyenTai_DenBen_ThanhCong == null
                        ? '#FB8C00'
                        : '#D10909'
                }`"
            >
                {{
                    ChiTietLenh.truyenTai_DenBen_ThanhCong == null
                        ? "Chưa truyền tải"
                        : ChiTietLenh.truyenTai_DenBen_ThanhCong == true
                        ? "Truyền tải thành công"
                        : "Truyền tải gặp lỗi"
                }}
            </div>
        </div>
        <div
            :class="row"
            style="line-height: 1"
            v-if="ChiTietLenh.truyenTai_DenBen_ThanhCong"
        >
            <!-- <i class="mdi mdi-circle-small"></i> -->
            <div :class="textLeft">Thời gian truyền tải đến bến</div>
            <div :class="textRight">
                {{
                    this.$Helper.Common.formatDateTime(
                        ChiTietLenh.thoiGianTruyenTaiDenBen
                    )
                }}
            </div>
        </div>
    </div>
</template>

<script>
import { DxButton } from "devextreme-vue";
import { DxToolbar, DxItem } from "devextreme-vue/toolbar";
import routerVue from "@sonphat/common-v1/components/router.vue";
export default {
    layout: "application",
    components: {
        DxButton,
        DxToolbar,
        DxItem,
        routerVue,
    },
    data() {
        return {
            row: "row align-center row-text mt-2 mb-2",
            rowTitle: "row  font-bold font-16 row-text mt-3",
            textLeft: "xs5 font-14 font-regular",
            textRight: "xs7 font-14 pl-3 font-medium text-xs-right",
            breadcrumbsItems: [
                {
                    id: "breadcrums_TraCuuLenh",
                    text: "Chi tiết lệnh",
                    disabled: true,
                    to: "",
                },
            ],
        };
    },
    computed: {
        screenWidth() {
            return this.$store.state.ManHinh.screenWidth;
        },
        ChiTietLenh() {
            return this.$store.state.TraCuuLenh.ChiTietLenh;
        },
        GetTextLaiXe() {
            return (this.ChiTietLenh.danhSachLaiXe || [])
                .map((e) => e.tenLaiXe)
                .join(" | ");
        },
    },
    methods: {},

    created() {},
};
</script>

<style scoped>
.row-text {
    border-bottom: 1px dashed #ccc;
}
</style>
